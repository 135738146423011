<div class="ContactForm">
  @if (submitStatus !== 'success') {
    <form  #form="ngForm" [formGroup]="contactForm" class="ContactForm__form" (ngSubmit)="submitContactForm(formDirective)" #formDirective="ngForm">
      <div class="grid-container">
        <div class="grid-x grid-margin-x">
          <div class="cell medium-6">
            <mat-form-field appearance="outline">
              <mat-label>First name</mat-label>
              <input data-qa-id="contactFirstName" matInput placeholder="First name" required formControlName="first_name">
              @if (firstName?.errors?.['required']) {
                <mat-error>First name is required</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-6">
            <mat-form-field appearance="outline">
              <mat-label>Last name</mat-label>
              <input data-qa-id="contactLastName" matInput placeholder="Last name" required formControlName="last_name">
              @if (lastName?.errors?.['required']) {
                <mat-error>Last name is required</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-6">
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input data-qa-id="contactEmail" matInput placeholder="E-mail" required formControlName="email">
              @if (email?.errors?.['required']) {
                <mat-error>Email is required</mat-error>
              }
              @if (email?.errors?.['email']) {
                <mat-error>Email is invalid</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-6">
            <mat-form-field appearance="outline">
              <mat-label>Phone</mat-label>
              <input data-qa-id="contactPhone" matInput placeholder="Phone" required formControlName="phone" mask="(000) 000-0000||+000000000000000" [validation]="false">
              @if (phone?.errors?.['required']) {
                <mat-error>Phone is required</mat-error>
              }
              @if (phone?.errors?.['pattern']) {
                <mat-error>Phone is invalid</mat-error>
              }
              @if (phone?.errors?.['minlength']) {
                <mat-error>Phone is invalid</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label>Company</mat-label>
              <input data-qa-id="contactCompany" matInput placeholder="Company name" required formControlName="company_name">
              @if (company?.errors?.['required']) {
                <mat-error>Company name is required</mat-error>
              }
            </mat-form-field>
          </div>
          @if (assetData()) {
            <div class="cell medium-12 offer">
              <mat-form-field appearance="outline">
                <mat-label>My Offer</mat-label>
                <input data-qa-id="offer" matInput placeholder="My Offer" formControlName="offer_price_native" [currencyMask]="{
                  align: 'left',
                  allowNegative: false,
                  precision: 0,
                  prefix: currencyService.selectedCurrency().symbol + ' ',
                  nullable: true,
                }">
                <mat-hint>Offer in {{currencyService.selectedCurrency().code}}</mat-hint>
              </mat-form-field>
            </div>
          }
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label>Comment (optional)</mat-label>
              <textarea data-qa-id="contactMessage" matInput placeholder="Message" formControlName="message" rows="3"></textarea>
            </mat-form-field>
          </div>
        </div>
        @if (!hideSubmitButton()) {
          <app-button
            btn-large
            btn-contained
            [disabled]="disabled"
          >Send message</app-button>
        }
        </div>
     </form>
  } @else {
    <div class="ContactForm__success">
      <div class="icon-bg"><mat-icon class="material-icons-outlined icon-large">mark_email_read</mat-icon></div>
      <h3 class="ContactForm__header">{{successTitle()}}</h3>
      <p class="ContactForm__copy">{{successBody()}}</p>
      @if (withCloseButton()) {
        <app-button
          btn-large
          btn-contained
          (click)="dialogRef()?.close()"
        >Close</app-button>
      }
  </div>
  }
</div>