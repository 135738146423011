<div
  class="AssetCard"
  tabindex="0"
>
  @if (asset(); as asset) {
    <div class="AssetCard__image">
      <div class="AssetCard__badges">
        @if (badgesConfig(); as badges) {
          <app-badges-container
            [enabled]="badges.enabled"
            [labelType]="badges.data.labelType"
            [labelModifier]="badges.data.labelModifier"
            [asset]="asset"
          ></app-badges-container>
        }
      </div>
      @if (asset.images && asset.images.length) {
        <img [legacyImageProcessor]="{
            guid: asset.images[0],
            transparent: false,
            type: LegacyImageServiceTypes.CarouselItem,
          }" 
          [alt]="asset.year + ' ' + asset.make + ' ' + asset.model"/>
      } @else {
        <img [src]="generalSettings.getDefaultImage()" alt="No photo available" />
      }
    </div>
    <div class="AssetCard__details">
      <div class="overline AssetCard__details--info1">
        {{asset.branch_city}}, {{asset.branch_state}} | {{asset | assetMeter : 'short'}}
      </div>
      <div class="subtitle-1-medium AssetCard__details--info2">
        {{asset.year}} {{asset.make}} {{asset.model}}
      </div>
      @if(asset.list_price && !asset.is_list_price_hidden) {
        <div class="AssetCard__details--footer">
          <div class="h6 price">
            {{asset | assetPrice : currencyService.selectedCurrency().code}}
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  }
</div>