import { Injectable, inject } from '@angular/core'
import { KeysService } from './keys.service'
import { LogService } from './log.service'

declare global {
  interface Window { grecaptcha: any; }
}

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {
  private _logger: LogService = inject(LogService)
  private keysService: KeysService = inject(KeysService)

  init() {
    this._logger.debug('ReCaptchaService.init()')
    this.loadReCaptcha()
  }

  private loadReCaptcha() {
    this._logger.debug('ReCaptchaService.loadReCaptcha()')
    const keys = this.keysService.keys()

    if (!keys.recaptcha_site_key) {
      this._logger.debug('ReCaptchaService.loadReCaptcha() - No key found')
      return
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${keys.recaptcha_site_key}`
    script.async = true
    document.body.appendChild(script)
    // this adds an object "grecaptcha" to the global window object
  }

  execute(action: string): PromiseLike<string> {
    if (!window.grecaptcha) {
      return new Promise((success) => success(''))
    }
    return window.grecaptcha.execute(this.keysService.keys().recaptcha_site_key, { action })
  }
}
