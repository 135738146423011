import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthModule } from '@auth0/auth0-angular'
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery'
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox'
import { register } from 'swiper/element/bundle'
import * as Sentry from '@sentry/angular'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomeModule } from './pages/home/home.module'
import { ConfigService } from './core/services/config.service'
import { DetailsModule } from './pages/details/details.module'
import { AssetService } from './core/services/asset.service'
import { SearchService } from './core/services/search.service'
import { SearchPageModule } from './pages/search/search-page.module'
import { ThemeService } from './core/services/theme.service'
import { FetchService } from './core/services/fetch.service'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { MockService } from './core/services/mock.service'
import { AdminService } from './core/services/admin.service'
import { LogLevel, LogService } from './core/services/log.service'
import { EventService } from './core/services/event.service'
import { Page404Module } from './pages/page404/page404.module'
import { ApiRequestService } from './core/services/api-request.service'
import { ClientService } from './core/services/client.service'
import { DevService } from './core/services/dev.service'
import { DialogService } from './core/services/dialog.service'
import { EnvironmentService } from './core/services/environment.service'
import { environment } from 'src/environments/environment'
import { CurrencyService } from './core/services/currency.service'
import { ToastService } from './core/services/toast.service'
import { ConfigVersionService } from './core/services/config-version.service'
import { ImageService } from './core/services/image.service'
import { BadgeService } from './core/services/badge.service'
import { PriceService } from './core/services/price.service'
import { CommonModule, DOCUMENT } from '@angular/common'
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { FilterService } from './core/services/filter.service'
import { JsonLogicService } from './core/services/json-logic.service'
import { HistoryService } from './core/services/history.service'
import { KeysService } from './core/services/keys.service'
import { AnalyticsService } from './core/services/analytics.service'
import { TagManagerService } from './core/services/tag-manager.service'
import { ReCaptchaService } from './core/services/recaptcha.service'
import { HeapService } from './core/services/heap.service'
import { InitDataService } from './core/services/init-data.service'
import { AppInitService } from './core/services/app-init.service'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { SeoService } from './core/services/seo.service'
import { GeneralSettingsService } from './core/services/general-settings.service'
import { FontService } from './core/services/font.service'
import { MediaLibraryService } from './core/services/media-library.service'
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import { MaintenanceGuardService } from './core/services/maintenance-guard.service'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getFirestore, provideFirestore } from '@angular/fire/firestore'
import { AssetMeterPipe } from './core/pipes/asset-meter.pipe'


// register swiper globally
register()

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    AuthModule.forRoot({
      ...environment.auth
    }),
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () => {
        appInitService.init()
      },
      deps: [
        AppInitService,
      ],
      multi: true,
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: DOCUMENT,
      useValue: document,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    AppInitService,
    InitDataService,
    ConfigService,
    SeoService,
    ClientService,
    KeysService,
    ThemeService,
    FontService,
    AssetService,
    SearchService,
    FetchService,
    ApiRequestService,
    MediaLibraryService,
    MockService,
    AdminService,
    LogService,
    EventService,
    DevService,
    DialogService,
    EnvironmentService,
    CurrencyService,
    ToastService,
    ConfigVersionService,
    ImageService,
    GeneralSettingsService,
    BadgeService,
    PriceService,
    FilterService,
    JsonLogicService,
    HistoryService,
    AnalyticsService,
    TagManagerService,
    ReCaptchaService,
    HeapService,
    MaintenanceGuardService,
    AssetMeterPipe,
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: true,
        exitAnimationTime: 250
      } as LightboxConfig
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        autoHeight: true,
        imageSize: 'cover'
      } as GalleryConfig
    },
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore())
  ]
})
export class AppModule { }
